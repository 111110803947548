const EN = {
  'lang-en': 'English',
  'lang-fr': 'French',
  'lang-de': 'German',

  from: 'from',

  'My account': 'My account',
  Products: 'Products',
  'for women from to': 'for women from {0} to {1}',

  '{0} products found for « {1} »': '{0} products found for « {1} »',
  Reset: 'Reset',
  Filter: 'Filter',
  'View all {0} products': 'View all {0} products',
  'Sort by ascending price': 'Sort by ascending price',
  'Sort by descending price': 'Sort by descending price',

  found: 'found',
  'Last products viewed': 'Last products viewed',
  'See my favorites': 'See my favorites',
  'See more dresses': 'See more dresses',
  'Add to Cart': 'Add to Cart',
  'Add to favorite': 'Add to favorite',
  'Not available': 'Not available',
  "We're sorry, this item is no longer available.":
    "We're sorry,<br>this item is no longer available.",
  'Not available, notify me when back in stock':
    'Not available, notify me when back in stock',
  'Notify me when back in stock': 'Notify me when back in stock',
  Send: 'Send',
  'You will like also': 'You will like also',
  'Continue my shopping': 'Continue my shopping',
  'The JMP news': 'The JMP news',
  'Do you wish to order by phone': 'Do you wish to order by phone',
  'Product association': 'Product association',
  'Similar products': 'Similar products',
  'your shopping cart': 'your shopping cart',
  articles: 'articles',
  Total: 'Total',
  Subtotal: 'Subtotal',
  Delivery: 'Delivery',
  'Your delivery': 'Your delivery',
  'Delivery address': 'Delivery address',
  'Total (VAT INCLUDED)': 'Total (VAT INCLUDED)',
  'Please enter or add the discount code here':
    'Please enter or add the discount code here',
  Command: 'Command',
  'Your Coupon Code': 'Your Coupon Code',
  Save: 'Save',
  'Complete With': 'Complete With',
  'My favorites': 'My favorites',
  'Validate my order': 'Validate my order',
  'ADD AN ADDRESS': 'ADD AN ADDRESS',
  'Edit an address': 'Edit an address',
  'First name': 'First name',
  'Last name': 'Last name',
  Address: 'Address',
  'Additional address': 'Additional address',
  'ZIP code': 'ZIP code',
  Country: 'Country',
  City: 'City',
  'Mobile phone': 'Téléphone mobile',
  Phone: 'Phone',
  'Address name': 'Address name',
  'Set as default delivery address': 'Set as default delivery address',
  'Set as default billing address': 'Set as default billing address',
  'Required fields': 'Required fields',
  'At least {0} characters': 'At least {0} characters',
  'Length should be 6 to 15': 'Length should be 6 to 15 characters',
  "By registering for an account, you agree to our terms of use. Please read our <a href='{0}' class='underline'>privacy policy.</a>":
    "By registering for an account, you agree to our terms of use. Please read our <a href='{0}' class='underline' target='_blank'>privacy policy.</a>",
  'My Address': 'My Address',
  'I am new here': 'I am new here',
  Register: 'Register',
  'Good morning': 'Good morning',
  Login: 'Login',
  "Login with Email/Password don't exists":
    "Login with Email/Password don't exists",
  'Good morning': 'Good morning',
  'Email address': 'Email address',
  Password: 'Password',
  'New password': 'New password',
  'Confirm password': 'Confirm password',
  'Forgot your password': 'Forgot your password',
  'Register with an email address': 'Register with an email address',
  'Or register with my social networks': 'Or register with my social networks',
  'Or connect with my social networks': 'Or connect with my social networks',
  'Date of birth': 'Birthday',
  Birthday: 'Birthday',
  'E-mail': 'E-mail',
  'E-mail address': 'E-mail address',
  optional: 'optional',
  'Privacy Policy': 'Privacy Policy',
  'Terms of use': 'Terms of use',
  'Legal Notice': 'Legal Notice',
  'Please select a size': 'Please select a size',
  'Validate my cart': 'Validate my cart',
  'Reserve this product in store': 'Reserve this product in store',

  'Delivered on': 'Delivered on',
  'See order': 'See order',
  Invoice: 'Invoice',
  'Return an item': 'Return an item',
  Invoicing: 'Invoicing',
  Sending: 'Sending',
  Information: 'Information',
  'Order Date': 'Order Date',
  'Order number': 'Order number',
  'Sending mode': 'Sending mode',
  'Payment method': 'Payment method',
  'Track my delivery': 'Track my delivery',
  'You have a question': 'You have a question',
  'Order Detail': 'Order Detail',
  Quantities: 'Quantities',
  'Download my invoice': 'Download my invoice',
  'Return requested': 'Return requested',
  'Return type': 'Return type',
  'Return status': 'Return status',
  'Order Summary': 'Order Summary',
  'Total products': 'Total products',
  'Only {0} left to take advantage of free home delivery':
    'Only {0} left to take advantage of free home delivery',
  'Estimated delivery': 'Estimated delivery',
  '<u>Free delivery from {0}</u> at home and at a pick-up point':
    '<u>Free delivery from {0}</u> at home and at a pick-up point',
  'We accept payment methods': 'We accept payment methods',
  'Your delivery adress': 'Your delivery adress',
  'Create a new delivery address': 'Create a new delivery address',
  'Edit this address': 'Edit this address',
  'Use another address for billing': 'Use another address for billing',
  'Your billing address': 'Your billing address',
  'Your delivery option': 'Your delivery option',
  'Continue to payment': 'Continue to payment',
  carrierType_Home: 'At home',
  carrierType_RelayPoint: 'In relay point',
  carrierType_Store: 'In stores',
  Edit: 'Edit',
  'See all products': 'See all products',

  'Payment method for {0}': 'Payment methods for {0}',
  'Payment methods': 'Payment methods',
  '100% secure. Your bank may ask you to authorize payment to complete your purchase.':
    '100% secure. Your bank may ask you to authorize payment to complete your purchase.',
  'I accept the general sales conditions':
    "By continuing my order, I acknowledge having read and accepted <a class='underline' href='{terms_link}' target='_blank'>the general conditions of sale</a>, as well as my wish to proceed with the payment of the order before any delivery.",
  'Credit card': 'Credit card',
  'Secure payment with {0}': 'Secure payment with {0}',
  'Pay with': 'Pay with',
  'You will be redirected to the secure payment site.':
    'You will be redirected to the secure payment site.',

  News: 'News',
  'Plus Size Fishion': 'Plus Size Fishion',
  'The brand': 'The brand',
  'Our shops': 'Our shops',
  Magazine: 'Magazine',
  Home: 'Home',
  Collection: 'Collection',
  Clothes: 'Clothes',
  'In the heart of the rock': 'In the heart of the rock',
  'The origin of the waves': 'The origin of the waves',
  'The power of roses': 'The power of roses',
  'Back to basic': 'Back to basic',
  'The power of roses': 'The power of roses',
  Lookbooks: 'Lookbooks',
  FAQ: 'FAQ',
  Discover: 'Discover',
  'See more articles': 'See more articles',
  'All items': 'All items',
  'See the lookbook': 'See the lookbook',

  'PAYMENT IN SEVERAL TIMES': 'PAYMENT IN SEVERAL TIMES',
  'Payment 100% secured': 'Payment 100% secured',
  'Pay in installments from 150': 'Pay in installments from 150',
  'Our secure payment methods': 'Our secure payment methods',
  'Bank card, Klarna 3X free of charge from €150, <br />Paypal, American Express':
    'Bank card, Klarna 3X free of charge from €150, <br />Paypal, American Express',
  'Delivery and returns': 'Delivery and returns',
  'Delivery in 2-3 days': 'Delivery in 2-3 days',
  'Easy returns and exchanges': 'Easy returns and exchanges',
  'Free 2-3 day delivery & Return': 'Free 2-3 day delivery & Return',
  'Refund & size exchange <br />Full details here.':
    'Refund & size exchange <br />Full details here.',
  'Customer Service': 'Customer Service',
  'Monday, Tuesday, Thursday, Friday (10.30am-1pm / 2pm-5.30pm)':
    'Monday, Tuesday, Thursday, Friday (10.30am-1pm / 2pm-5.30pm)',
  'Receive our news in preview and take advantage of our exclusive offers!':
    'Receive our news in preview and take advantage of our exclusive offers !',
  'Let’s stay connected': 'Let’s stay connected',

  'Frequently Asked Questions': 'Frequently Asked Questions',
  'Delivery & Returns': 'Delivery & Returns',
  'Track my order': 'Track my order',
  'Follow-up of my order outside France':
    'Follow-up of my order outside France',
  GTC: 'GTC',
  'Legal Notice': 'Legal Notice',
  Privacy: 'Privacy',
  'Cookie settings': 'Cookie settings',
  'Join us': 'Join us',
  Manifesto: 'Manifesto',
  'Our commitments': 'Our commitments',
  'The Diary': 'The Diary',
  Lookbooks: 'Lookbooks',
  '89 Rivoli street, 75001 Paris': '89 Rivoli street, 75001 Paris',
  '95 Saint-Lazare street, 75009 Paris': '95 Saint-Lazare street, 75009 Paris',
  '92 Alesia street, 75 014 Paris': '92 Alesia street, 75 014 Paris',
  '57 Poland Street, 78100 Saint Germain-en-Laye':
    '57 Poland Street, 78100 Saint Germain-en-Laye',
  '2 Jean Roisin street, 59 800 Lille': '2 Jean Roisin street, 59 800 Lille',
  CGV: 'CGV',
  HELP: 'HELP',
  ABOUT: 'ABOUT',
  'Need help': 'Need help',
  page_faq_disc:
    'A question about your order, its delivery, the possible payment methods or even returns, exchanges and refunds, the answer to your questions is surely in our FAQ and otherwise you can contact us',

  'My profile': 'My profile',
  'My information': 'My information',
  'My addresses': 'My addresses',
  Disconnect: 'Disconnect',
  'My orders': 'My orders',
  'my shopping carts': 'my shopping carts',
  'My returns': 'My returns',
  'My favorites': 'My favorites',
  'Saved for later': 'Saved for later',

  'Default Delivery': 'Default Delivery',
  'Default Billing': 'Default Billing',
  'Edit Address': 'Edit Address',
  'Delete this address': 'Delete this address',

  'My loyalty program': 'The program',
  'My Membership': 'Join the program',
  'My advantages': 'Get points',
  'My history': 'History',
  'Join for free': 'Join for free',
  'Use my points': 'Use my points',
  'Get the reward': 'Get the reward',
  'MY LOYALTY CARD': 'MY LOYALTY CARD',
  'LOYALTY CARD': 'LOYALTY CARD',
  Yes: 'Yes',
  No: 'No',
  loyalty_page_title:
    '<b>CLUB JMP</b> <br /> The loyalty program that benefits you',
  loyalty_page_disc:
    "<b>CLUB JMP</b> is JMP's loyalty program where every customer counts. The more you enjoy it, the more your benefits evolve. It's free and without obligation !",

  'New account': 'New account',
  'You can create an account in the following steps':
    'You can create an account in the following steps',
  'Continue with guest Checkout': 'Continue with guest Checkout',

  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',

  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  'Please type your address': 'Please type your address',
  'Find an address': 'Find an address',
  Search: 'Search',

  Fullname: 'Fullname',
  'Card number': 'Card number',
  'Expiry date': 'Expiry date',
  CVC: 'CVC',

  Checkout: 'Checkout',
  'The three-digit code is on the back of the card':
    'The three-digit code is on the back of the card',
  'This order has already been paid': 'This order has already been paid',

  Preference: 'Preference',
  'Make your purchases in': 'Make your purchases in',
  'Your language': 'Your language',
  'Your currency': 'Your currency',
  Currency: 'Currency',
  'Contact language': 'Contact language',
  'Update preferences': 'Update preferences',

  'Products in my cart': 'Products in my cart',
  account: 'account',
  Favorites: 'Favorites',
  Cart: 'Cart',
  Connection: 'Connection',
  'See all my favorites': 'See all my favorites',
  'Free delivery from €150 at home and at a pick-up point. Payment in 3x free of charge from €150':
    '<u>Free delivery from €150</u> at home and at a pick-up point. Payment in 3x free of charge from €150',

  Quantity: 'Quantity',
  Size: 'Size',
  'See more': 'See more',
  'Filter by': 'Filter by',
  Sizes: 'Sizes',
  Colors: 'Colors',
  'Price from {0} to {1}': 'Price from {0} to {1}',

  '{0} points available': '{0} Points available',
  'My loyalty points': 'My loyalty points',
  'Total : {0}pts': 'Total : {0} pts',
  'My point history': 'My point history',
  'Welcome to your loyalty area.': 'Welcome to JMP Club.',
  'Earn points and enjoy your benefits.':
    'Earn points and enjoy your benefits.',
  'Show my loyalty card': 'Show my loyalty card',
  'Redeem my points': 'Redeem my points',
  'Get points': 'Earn points with events',
  modal_event_body:
    'Once you have obtained your new points, take into account the validity date of your points in order to use them. We will notify you before your points expire by email if you forget.',
  'points available': 'Points available',
  'Buy in store and online': 'Buy in store and online',
  'Earn points by purchasing JMP items and redeem them for discounts.':
    'Earn points by purchasing JMP items and redeem them for discounts.',
  'Buy now': 'Buy now',
  'Birthday present': 'Birthday present',
  'Receive a special gift for your birthday.':
    'Receive a special <br> gift for your birthday.',
  points: 'Points',
  'Identify yourself in store': 'Identify yourself in store',
  'Present your JMP loyalty card in store. You will find the code in « My Account »':
    'Present your JMP loyalty card in store.<br> You will find the code in « My Account »',
  'My membership in the {0} loyalty program':
    'My membership in the {0} loyalty program',
  'Would you like to join the {0} loyalty program?':
    'My membership to the {0} loyalty program',
  loyalty_form_text:
    '<p> Join the JMP loyalty program for free to accumulate points with each order and benefit from discount coupons, receive exclusive offers and progress through 3 increasingly generous statuses. Also enjoy all JMP privileges in your JMP stores. </p> <br /> <p> The JMP loyalty program is reserved for any natural person over the age of 18 </p>',
  'Find out more about the loyalty program':
    'Find out more about the loyalty program',
  'Happy birthday': 'Happy birthday',
  'Your points have expired': 'Your points have expired',
  'Order No': 'Order N°',
  '{0} points received': '{0} points received',
  '{0} points used': '{0} points used',
  'Loyalty program membership': 'Loyalty program membership',
  'Join the loyalty program': 'Join the JMP Club',

  'Type something': 'Type something',

  'Or 3x {0} with the <u>payment in 3x free</u>':
    'Or 3x {0} with the <u>payment in 3x free</u>',
  Color: 'Color',
  'Availability in store': 'Availability in store',
  'Free delivery': 'Free delivery',
  days: 'days',
  'Free return': 'Free return',
  'Secure payment': 'Secure payment',
  'free of charge': 'free of charge',
  Measurements: 'Measurements',
  'How do I take my measurements?': 'How do I take my measurements ?',
  'Please refer to the charts below to determine your size':
    ' Please refer to the charts below to determine your size',
  measurements_disc:
    'Take your measurements using a tape measure, then refer to the table below to find your size. Contact our customer service if you need to know the fit of a particular item at {0}.',
  'Chest size': 'Chest size',
  'Place the tape measure at armpit level, where it is strongest, and go around.':
    'Place the tape measure at armpit level, where it is strongest, and go around.',
  'Waist size': 'Waist size',
  'Place the tape measure at the hollow of your waist, below your ribs and above your hips.':
    'Place the tape measure at the hollow of your waist, below your ribs and above your hips.',
  'Hip circumference': 'Hip circumference',
  'Place the tape measure at the fullest part of your hips.':
    'Place the tape measure at the fullest part of your hips.',
  'Size guide': 'Size guide',
  Description: 'Description',
  'Material and fit': 'Material and fit',
  Chest: 'Chest',
  Hips: 'Hips',
  times: 'times',

  sofort_disc:
    'SOFORT was founded in 2005 in Munich, Germany, and is the #1 Bank Transfer provider in Europe, with big plans to grow in the United Kingdom. With wide market coverage and strong brand awareness, we’re also one of the top 3 most-used payment methods overall in Germany and Austria.',
  bancontact_disc:
    'Paying small amounts very easily with Bancontact: easy and fast. Paying any amount, even the smallest, is very easy with the Bancontact card.',
  ideal_disc:
    'Do you want your customers to pay more often with iDEAL? With a few simple changes to your checkout page, you can encourage your customers to pay quickly, safely and securely with iDEAL.',
  giropay_disc:
    'Pay online with Giropay - securely and directly from your current account.',
  'Payment in installments with Alma': 'Payment in installments with Alma',
  alma_disc: 'Payment in 2, 3 or 4 installments without fees.',
  Payment: 'Payment',
  Continue: 'Continue',
  'Continue mon shopping': 'Continue mon shopping',
  'Continue Shopping': 'Continue Shopping',
  'Free delivery & easy return': 'Free delivery & easy return',
  Next: 'Next',
  'To see the availability of this product in stores, please select the desired size.':
    'To see the availability of this product in stores, please select the desired size.',
  Returns: 'Returns',
  Loyalty: 'Loyalty',
  back_in_stock_nb:
    'By entering your email, you agree that Jean Marc Philippe will use your email address to keep you informed of the availability of this product and suggest alternatives if the product is not back in stock within 15 days. This stock alert will be deleted after 15 days. You have the right to access, modify, delete the processing of your data on simple request to the data protection department (data@jeanmarcphilippe.com). For more information, please see our privacy policy.',
  back_in_stock_validate:
    "<p> We have successfully recorded your request. A confirmation has been sent to you by email at {mail} </p> <p>Product back in stock?</p> <ul class='ul-list'> <li> We will send you a email to let you know when this product is back in stock. </li> </ul>",
  copyright:
    "© {0}, {1} made with <a href='{2}' class='underline'>Digipart Commerce Cloud</a>",

  order_thanks:
    "<h2> Thank you {name}, <br /> For your order </h2> <p> We have successfully registered your purchase and thank you for your confidence. </p> <p> We invite you to check the details of your order and your delivery address. <br /> If you notice an error, please contact us by email at <a href='mailto:{mail}' >{mail}</a > or by phone at <a href='tel:{tel} '>{tel_text}</a>. </p> <p> <i> We will inform you of the next steps for preparing and shipping your order by email. You can also follow the status of your order in your account on jeanmarcphilippe.com in the tab <a href='{order_link}'>my orders </a>. </i> </p>",
  'A question about your order?': 'A question about your order ?',
  'See the details of my order': 'See the details of my order',
  Order: 'Order',
  order_thanks_decline: `<h2>ORDER NOT FINALIZED!</h2>
  <p>Your order was abandoned during the payment process.</p>
  <p>We invite you to return to <a href='{cart_link}' >your basket</a> to check it, choose your delivery option and your payment method to finalize your order. </p>
  <p>If you notice an error, please contact us by email at <a href='mailto:{mail}' >{mail}</a > or by phone at <a href='tel:{tel}' >{tel_text}</a>.</p>`,
  'See the details of my cart': 'See the details of my cart',

  newsletter_title: '€20 OFFERED FOR THE FIRST ORDER',
  newsletter_text:
    'Subscribe to our newsletter and receive <b>€20 off</b> for your first order on the new collection.*',
  newsletter_modal_nb:
    '*By registering your email, you accept that Jean Marc Philippe uses your email address to send you messages by email.',

  'thank you for subscribing': 'Thanks for <br/> subscribing !',
  newsletter_confirmation:
    'A confirmation has been sent to you by email at {mail}. You will soon receive the JMP newsletter.',

  'Create your JMP space for a personalized shopping experience':
    'Create your JMP space for a personalized shopping experience',
  'No product added to cart': 'No product added to cart',
  'No favorites added': 'No favorites added',
  'You have not added any favorites yet':
    'You have not added any favorites yet.',

  Product: 'Product',
  Shop: 'Shop',
  'Contact details': 'Contact details',
  Confirmation: 'Confirmation',
  Article: 'Article',
  'Your contact details': 'Your contact details',
  Civility: 'Civility',
  Madam: 'Madam',
  Mr: 'Mr.',
  'I authorize {0} to inform me of good plans and news':
    'I authorize {0} to inform me of good plans and news',
  Confirm: 'Confirm',
  'See the store': 'See the store',
  'thank you, for your reservation':
    'Thank you {name}, <br /> for your reservation',
  reservation_confirmes_disc:
    '<p>The reservation is free and without obligation to purchase.</p> <br /> <p> <i> The store will confirm to you shortly that the product has been set aside by email or telephone. <u>Wait to receive this confirmation</u> before going to the store! </i> </p>',
  'Last product in stock': 'Last product in stock',
  'Out of stock': 'Out of stock',
  'See on the map': 'See on the map',
  'Schedules and info': 'Schedules and info',
  'Choose This Shop': 'Choose This Shop',
  Previous: 'Previous',
  'Out Of Stock': 'Out of stock',
  'Limited Stock': 'Limited stock',
  'In Stock': 'In stock',
  'Please select a store': 'Please select a store',

  'Create a return': 'Create a return',
  create_return_confirmed:
    "<h2>Return request confirmed</h2> <p>We have registered your return request.</p> <p> Below you will find your return note and your transport label to return your order No. 37896. Please complete the return form to attach to your parcel and use the transport label to stick on your parcel. If you have any questions about returns, follow the return instructions. </p> <p> <i> We will inform you of the reception of your package in order to proceed with the exchange of size or the refund requested. You can also follow the status of your return in your account on jeanmarcphilippe.com in the tab <a href='{myReturnsLink}'>my returns </a>.. </i> </p>",
  'Documents of your return': 'Documents of your return',
  Return: 'Return',
  'Return voucher': 'Return voucher',
  'Transport label': 'Transport label',
  'Explanatory note on returns': 'Explanatory note on returns',
  'Return detail': 'Return detail',
  Details: 'Details',
  'View original order': 'View original order',
  'View Return': 'View Return',
  'Return Tracking': 'Return Tracking',
  'Return request validated': 'Return request validated',
  'Return documents available': 'Return documents available',
  'Return parcel sent': 'Return parcel sent',
  'Your points have expired': 'Your points have expired',
  'Return received, processing in progress':
    'Return received, processing in progress',
  'Return complete': 'Return complete',
  Exchange: 'Exchange',
  Refund: 'Refund',
  'Select a size to continue': 'Select a size to continue',

  'My returns': 'My returns',

  'Use at least 6 characters': 'Use at least 6 characters',
  'By registering for an account, you agree to our terms of use. Please read our privacy policy.':
    'By registering for an account, you agree to our terms of use. Please read our privacy policy.',
  'All the questions': 'All the questions',
  FEATURED: 'FEATURED',
  'Reset instructions have been sent to':
    'Reset instructions have been sent to',
  'You did not receive the email': 'You did not receive the email',
  'Check your spam folder': 'Check your spam folder',
  'Check the given email to reset the password':
    'Check the given email to reset the password',
  'Wait a few minutes before trying again, as some requests are slow to process':
    'Wait a few minutes before trying again, as some requests are slow to process',
  'See our addresses': 'See our addresses',
  Schedule: 'Schedule',
  'Contact us': 'Contact us',
  Itinerary: 'Itinerary',
  'Open in map': 'Open in map',
  'Schedules and info': 'Schedules and info',
  'other relay points nearby': 'other relay points nearby',
  'Choose another relay point': 'Choose another relay point',
  'Your address': 'Your address',
  Map: 'Map',
  'Choose this parcel relay': 'Choose this parcel relay',
  'Promo Code / Voucher': 'Promo Code / Voucher',
  form_abandoned_nb:
    'By registering your email, you accept that Jean Marc Philippe uses your email address to send you messages concerning your basket during the finalization of the purchase.',
  'Do you hesitate': 'Do you hesitate',
  'We have registered your request. A confirmation has been sent to you by email at {mail}':
    "We have registered your request. A confirmation has been sent to you by email at <a href='{order_link}'>{mail}</a>",
  'Your promo codes': 'Your promo codes',
  'the product you requested no longer exists':
    'the product you requested no longer exists !',
  'This category does not exist': 'This category does not exist !',
  "This shop doesn't exist": "This shop doesn't exist !",
  'This page does not exist !': 'This page does not exist !',
  Newsletter: 'Newsletter',
  'I would like to receive the {0} newsletter':
    'I would like to receive the {0} newsletter',

  'My newsletter subscription': 'My newsletter subscription',
  newsletter_nb:
    'Our editorial team sends you personalized content to inform you of our latest news, new trends, collection releases, our best offers, promotions and sales. You can change your preferences here at any time.',
  account_news_letter_p1:
    "I have read and understood the <a href='{0}' class='underline'>Privacy and Cookies Policy</a>, and agree to receive personalized marketing communications from {1} via email.",
  account_news_letter_p2:
    'If you change your preferences, the changes may take up to 7 days to take effect.',
  'You have successfully registered': 'You have successfully registered',
  'You have successfully unsubscribed': 'You have successfully unsubscribed',

  'Customer service': 'Customer service',
  Assistance: 'Assistance',
  'My requests': 'My requests',
  'Do you need help with a recent article, {0} ?':
    'Do you need help with a recent article, {0} ?',
  'Select the item below that you need help with or get help with something else':
    "Select the article you need help with below or <a href='{faq_url}' class='underline'>get help for something else</a>",
  'Required Field': 'Required Field',
  'Your message': 'Your message',
  'Type your message': 'Type your message',
  'Send your request': 'Send your request',
  'Send your message': 'Send your message',
  'Last message': 'Last message',
  Message: 'Message',
  Subject: 'Subject',
  ticket_state__Closed: 'Request processed',
  ticket_state__InProcess: 'Current request',
  ticket_state__Opened: 'Open request',
  'Add message': 'Add message',
  'View chat history': 'View chat history',
  'Details of the ASSISTANCE request': 'Details of the ASSISTANCE request',
  'Your evaluation has been successfully updated':
    'Your evaluation has been successfully updated',
  'You can only modify your evaluation within a duration of 24 hours after the evaluation.':
    'You can only modify your evaluation within a duration of 24 hours after the evaluation.',
  Success: 'Success',
  Error: 'Error',
  'See history': 'See history',
  'ASSISTANCE in progress': 'ASSISTANCE in progress',
  estimated_delivery:
    '<b>Estimated delivery on {date}</b> if you order before 12 noon. <br />Delivery in <b>{country}</b>',
  estimated_delivery2:
    '<b>Estimated delivery between {dateMin} and {dateMax}</b>. <br />Delivery in <b>{country}</b>',

  Accessories: 'Accessories',
  'Last chance': 'Last chance',
  'Legal notice': 'Legal notice',

  home_title: 'JMP · Paris  · Jean Marc Philippe - JMP - Jean Marc Philippe',
  home_meta_discription:
    'Fashion for women over 42 · JMP Official E-Boutique · Secure payment · Satisfied or refunded · After-sales service at your service.',

  order_state_created: 'Created',
  order_state_preparation: 'in preparation',
  order_state_shipped: 'Shipped',
  order_state_delivered: 'Delivered',
  order_state_canceled: 'Canceled',

  Le: 'AT',
  shops_url: 'shops',
  shop_url: 'shop',
  faq_livraisons_url: '/content/faq/deliveries',

  'Page not found': 'Page not found',

  'This product is no longer available in the chosen version or quantity, modify or delete this product':
    'This product is no longer available in the chosen version or quantity, modify or delete this product',
  'This product already exists with this size, modify or delete to continue':
    'This product already exists with this size, modify or delete to continue',

  'Length should more than {0}': 'Length should more than {0}',
  'Please input correct email address': 'Please input correct email address',
  'Enter your email address below to receive a reminder of this basket and your future cart.':
    'Enter your email address below to receive a reminder of this basket and your future cart.',

  'I agree': 'I agree',
  coockis_bar_text: `Jean Marc Philippe uses technical cookies to ensure the proper functioning of the site, to personalize the content and to offer you a tailor-made experience. By clicking on "Accept cookies", you authorize the storage of all cookies on your device. By closing this banner, you will continue browsing using only technical cookies. To learn more about cookies, you can read our cookie policy in <a class='underline' href='{0}' target='_blank'>our terms and conditions</a>.`,
  'Sorted by': 'Sorted by',
  'By default': 'By default',
  'Ascending price': 'Ascending price',
  'Decreasing price': 'Decreasing price',
  Newest: 'Newest',
  Oldest: 'Oldest',
  'Choose my delivery': 'Choose my delivery',

  'Summer sales': 'Sommerschlussverkauf',
  Sales: 'Sales',
  'Summer Sales': 'Summer Sales',
  'Privacy Policy': 'Privacy Policy',

  'Please enter an address': 'Please enter an address',
  'Address line 2 (optional)': 'Address line 2 (optional)',
  'Apartment, building, floor, door code, etc.':
    'Apartment, building, floor, door code, etc.',
  'Choose the reason for the return': 'Choose the reason for the return',
  register_newsletter:
    'Yes, I want to receive the latest news by email and benefit from exclusive offers and discounts. You can unsubscribe at any time.',
  register_loyalty:
    "Yes, I would like to join the <a href='{0}' class='underline' target='_blank'>JMP loyalty program - Privileges</a> for free to accumulate points with each order and benefit from discount vouchers (as a welcome gift 250 points or €10 discount).",

  'A question about returns?': 'A question about returns ?',

  'Email does not exist': 'Email does not exist',
  'There is something not right': 'There is something not right',

  'Reset your password': 'Reset your password',
  'Back to login': 'Back to login',
  Previous: 'Previous',
  autologin_text: 'Please wait, you will be redirected to the profile page...',
  'The password was changed successfully':
    'The password was changed successfully !',
  unsubscribe: 'unsubscribe',
  'enregistrer sur le téléphone': 'Save on phone',
  'Use my points': 'Use my points',
  'The statuses': 'The statuses',
  'Turn your points into rewards': 'Turn your points into rewards',
  'Take advantage of your loyalty program...':
    'Take advantage of your loyalty program events to earn points and move to higher levels !',

  'Discover our 3 statuses': 'Discover our 3 statuses',
  'With each of your purchases or events, accumulate points and enjoy rewards!':
    'With each of your purchases or events, accumulate points and enjoy rewards!',
  ESSENTIAL: 'ESSENTIAL',
  PREMIUM: 'PREMIUM',
  'From 0 to 499 points': 'From 0 to 499 points',
  'Loyalty offer': 'Loyalty offer',
  'Birthday offer': 'Birthday offer',
  'Invitation to private sales': 'Invitation to private sales',
  '3x interest-free payment': '3x interest-free payment',
  '1 simple alteration / year offered': '1 simple alteration / year offered',
  'And many other surprises': 'And many other surprises',
  'My Membership': 'My Membership',
  EXCEPTION: 'EXCEPTION',
  'From 500 to 1999 points': 'From 500 to 1999 points',
  '2 simple alterations / year offered': '2 simple alterations / year offered',
  'Morphostyle session by appointment': 'Morphostyle session by appointment',
  'Invitation to events': 'Invitation to events',
  'And many other surprises': 'And many other surprises',

  'From 2,000 points': 'From 2,000 points',
  'Unlimited touch-ups offered': 'Unlimited touch-ups offered',
  'excluding marked down product': 'excluding marked down product',
  'Free premium delivery': 'Free premium delivery',
  'No minimum purchase': 'No minimum purchase',
  'get loyalty points': 'get loyalty points',
  Event: 'Event',
  All: 'All',
  available: 'Available',
  expired: 'Expired',
  used: 'Used',
  'Get the reward': 'Get the reward',
  'See the reward': 'See the reward',
  'Expired reward': 'Expired reward',
  'Reward used': 'Reward used',
  'Only <b>{0} pts</b> left to obtain this reward':
    'Only <b>{0} pts</b> left to obtain this reward',
  Close: 'Close',
  'Collect points': 'Collect points',
  'Get rewards': 'Get rewards',
  'GIFT CARD': '{} GIFT CARD',
  'to spend as you wish! Store, website… treat yourself !':
    '{0} to spend as you wish! Store, website… treat yourself !',
  loyalty_path: 'loyalty-program',
  points: 'points',
  loyalty_reward_congratulations:
    'Congratulations {name} ! <br /> The reward is activated on your account.',
  loyalty_reward_body: `<p>You can now take advantage of it.</p>
                        <p>
                            <i>
                              Find below the information about your reward and also
                              in the my rewards page for the duration of its validity.
                            </i>
                        </p>`,
  'GIFT CARD': 'GIFT CARD',
  Reward: 'Reward',
  Copy: 'Copy',
  Value: 'Value',
  'Valid until': 'Valid until',
  'Loyalty program': 'Loyalty program',
  Discount: 'Discount',
  'Get the points': 'Get the points',
  'Event completed': 'Event completed',
  'Your cart is empty': 'Your cart is empty',
  "It looks like you haven't added anything to your cart":
    "It looks like you haven't added anything to your cart",
  'This product does not exist !': 'This product does not exist !',

  'Refer a friend': 'Refer a friend',
  refer_path: 'refer-a-friend',

  refer_stitle_page:
    'Refer your friends and get discounts on your orders. 15€ free for you and your friend !',
  refer_step1_title:
    "Enjoy <strong>€15 off*</strong> and your friends too. Everybody wins ! <a target='_blank' href='{link}' class='underline'> How it works ? </a>",
  refer_step1_li1: 'Share your referral link with your friends',
  refer_step1_li2: 'When they use it, they get €15 off their first order',
  refer_step1_li3:
    "Once their order is placed, we will offer you €15 too. It's easy !",
  refer_step1_btn_create: 'CREATE YOUR ACCOUNT AND ENJOY €15 OFFERED',
  refer_step1_btn_offred: 'ENJOY €15 OFFERED',
  refer_step1_nb: '*Offer valid from 100€ of purchases.',
  refer_step1_qt: 'Learn more about the referral program',
  refer_step2_title: 'Register to start sharing',
  refer_step3_title:
    '<strong>{last_name}, take advantage of €15 off*<br/> </strong>and your friends too. Everybody wins !',

  refer_form_step_link_title: 'Simply share this link with your friends:',
  refer_form_step_link_nb:
    'We have also sent you this link so you can easily pass it on to your friends.',
  refer_form_step_email_header: '<span>From:</span> <span>{email} </span>',
  refer_form_step_email_body: `subject=Get%20€15%20Discount%20on%20Your%20First%20Order!&body=Hello,%0D%0A%0D%0AJMP%20gave%20me%20a%20€15%20discount%20to%20share%20with%20my%20friends%20for%20their%20first%20order.%20And%20I%20also%20get%20€15!%20Everyone%20wins!%0D%0A%0D%0AUse%20this%20link%20to%20get%20your%20€15%20discount:%20{link}`,
  refer_form_step_email_body_html: `<p>Hi,</p>
                              <p>JMP gave me a €15 reduction to share with my friends for their first order. 
                              And I too benefit from 15€. Everybody wins !</p>
                              <p>If you use the link below, you will benefit from €15 off your first JMP order. 
                              Once your purchases are made, I will also benefit from a €15 discount. Everybody wins !</p>
                              <p>Here is the link to benefit from the 15€: <span>{link}</span></p>`,

  refer_form_step_facebook_title: 'Get €15 off at JMP',
  refer_form_step_facebook_body:
    'JMP has given me a €15 discount to share with my friends for their first order. And I also get €15. Everybody wins!',
  refer_form_step_btns_copy: 'COPY LINK',
  refer_form_step_btns_email: 'SEND BY EMAIL',
  refer_form_step_btns_facebook: 'SHARE WITH FACEBOOK',
  refer_form_step_btns_sms: 'SEND OFFER VIA MY MESSAGING',
  refer_form_copy_title: 'YOU CAN ALSO SHARE A LINK:',
  refer_form_step_whatsapp_body: `Hello,%0D%0A%0D%0AJMP%20gave%20me%20a%20€15%20discount%20to%20share%20with%20my%20friends%20for%20their%20first%20order.%0D%0AAnd%20I%20also%20get%20€15!%0D%0A%0D%0AEveryone%20wins!%0D%0A%0D%0AHere's%20the%20link:%20{link}`,
  refer_form_step_whatsapp_body_html: `<p>Hi,</p>
                                  <p>JMP gave me a €15 reduction to share with my friends for their first order. 
                                  And I too benefit from 15€. Everybody wins !</p>
                                  <p>If you use the link below, you will benefit from €15 off your first JMP order. 
                                  Once your purchases are made, I will also benefit from a €15 discount. Everybody wins !</p>
                                  <p>Here is the link to benefit from the 15€: <span>{link}</span></p>`,
  refer_form_step_btns_whatsapp: 'SEND OFFER VIA MY whatsapp',

  sponsored_page_title: 'Your friend invites you',
  sponsored_page_stitle:
    'Enjoy €15 discount* offered by your friend! Once your order is validated, your friend will also receive €15 discount* thanks to you.',
  sponsored_step1_title:
    '<strong>Enjoy €15 discount*</strong> thanks to your friend.',
  sponsored_step1_p:
    'Simply enter your email address below to get your discount code.',
  sponsored_step1_submit: 'RECEIVE €15 DISCOUNT',
  sponsored_step2_title:
    '<strong>Enjoy €15 discount*</strong> thanks to your friend.',
  sponsored_step2_p:
    'Here is your discount code to get a €15 discount on your first order. This offer is valid for 6 days, until {date}. We have also sent you this offer by email.',
  sponsoredForm_cgv:
    '*Yes, I agree to register for the sponsorship program. By registering you accept our terms of use and our sponsorship program',
  'learn more': 'Learn more',
  sponsored_step1_form_nb:
    'By registering you join the sponsorship program for free which allows you to obtain discount vouchers for your next order.',

  Copied: 'Copied',
  'This code is already used': 'This code is already used',
  'This code is disabled': 'This code is disabled',

  account_referal_table_title: 'FOLLOW-UP OF YOUR REFERRALS',
  account_referal_table_disc:
    'Refer your friends and get discounts on your orders. €15 offered for you and your friend as soon as they place an order!',
  waiting: 'Waiting',
  registred: 'Registred',
  won: 'Won',
  'invitation sent': 'INVITATION SENT',
  'order completed': 'ORDER COMPLETED',
  'guest registered': 'GUEST REGISTERED',
  Referral: 'REFERRAL',
  'Refer friends': 'Refer Friends',
  'My referrals': 'My Referrals',
  'Track your sponsorships': 'Track Your Sponsorships',
  'Sponsorships made': 'SPONSORSHIPS MADE',
  'Guest orders': 'GUEST ORDERS',

  'Discount code applied': 'Discount code applied',
  'Apply discount code': 'Apply discount code',

  'Loyalty: reward unlocked': 'Loyalty: reward unlocked',
  'Discount code': 'Discount code',
  'Apply your loyalty discount codes to your basket.':
    'Apply your loyalty discount codes to your basket.',

  'My rewards unlocked': 'My rewards unlocked',
  'My rewards': 'My rewards',
  Language: 'Language',
  'You currently have no returns': 'You currently have no returns',
  'You can create a new return from My Orders.':
    'You can create a new return from My Orders.',

  'exchanges-and-refunds': 'exchanges-and-refunds',
  'You have no rewards': 'Accumulate loyalty points to unlock new ones.',
  until: 'until',

  'Secure credit card payment': 'Secure credit card payment',
  'Simple, fast and reliable, Stripe protects your transactions with cutting-edge technologies. Buy with confidence!':
    'Simple, fast and reliable, Stripe protects your transactions with cutting-edge technologies. Buy with confidence!',

  'Order as a guest': 'Order as a guest',
  'Guest order': 'Guest order',
  'Discover our universe': 'Discover our universe',

  'Our history ; 40 years of fashion': 'Our history ; 40 years of fashion',
  "In line with women's curves": "In line with women's curves",
  'Creation with audacity and passion': 'Creation with audacity and passion',
  'Responsible manufacturing in France': 'Responsible manufacturing in France',
  'Our news in the newspaper': 'Our news in the newspaper',

  'Return window closed': 'Return window closed',

  'by discount': 'by discount',
  'By category': 'By category',
  'Please complete your address by adding a mobile phone number':
    'Please complete your address by adding a mobile phone number',

  state: 'State',
  'wallet-dialog-text':
    'Present your loyalty card in store and accumulate points throughout your experience and your purchases. Check your points balance and convert your loyalty points into discounts.',

  'Authentication by Email/Password has failed or has not been provided.':
    'Incorrect email or password',
  'Bank card': 'Credit card',
  'Payment by credit card, Google Pay and Apple Pay.':
    'Payment by credit card, Google Pay and Apple Pay.',
  'You will be redirected to PayPal to finalize your payment.':
    'You will be redirected to PayPal to finalize your payment.',
  'Payment in 3 installments without fees.':
    'Payment in 3 installments without fees.',

  'delivery between': 'Delivery between {from} and {to}',
  Preorder: 'Preorder',
  preorder_readmore: `<span>Pre-order</span>
                      <ul>
                        <li>Benefit from a 10% discount on your pre-ordered item.</li>
                        <li>The item will be shipped as soon as we have it in stock, according to the delivery time specified in the item description. We reserve the right to make changes, and in such cases, we will inform you by email.</li>
                        <li>Shipping options can be selected before payment. If you order multiple items, we will ship the available items immediately in a first shipment, and the pre-ordered items later in a separate shipment.</li>
                        <li>Your right to return starts from the day you receive the item.</li>
                      </ul>
                      `,

  how_to_earn_points: 'How to earn points?',
  join_program: 'Join our JMP Connect program',
  first_250_points_offered: 'your first 250 points are offered',
  welcome_gift: 'Welcome gift',
  points: 'Points',
  discount_text:
    "that's {amount} off your next purchase in-store or on jeanmarcphilippe.com",
  accumulate_points: 'Accumulate points',
  exchange_points:
    'Use your points anytime and decide how many points you want to exchange',
  discount: 'discount',
  checkout: 'Checkout',
  additional_order_points:
    'In addition to the points collected from your orders',
  birthday: 'Birthday',
  birthday_discount_text:
    'During your birthday month. Usable once per year excluding promotions',
  social_media: 'Social media',
  follow_social_media:
    'Follow us on Instagram, TikTok, Facebook and collect points',
  register: 'Register',
  learn_more: 'Learn more',
  use_your_points: 'USE YOUR POINTS',
  use_points_anytime:
    'Use your points anytime and decide how many points you want to exchange',
  on_website: 'on jeanmarcphilippe.com',
  connect_to_access_wallet:
    'Make sure to log in to access your wallet on the cart page.',
  in_stores: 'in stores',
  generate_discount_online:
    'Generate your discount directly from your wallet on our website',
  awards_catalog_title: 'Rewards Catalog',
  use_points_choose_reward:
    'Use your points and choose the reward that makes you the happiest:',
  reward_types: 'gift cards, movie sessions, and much more!',
  gift_card_20_euro: '20€ Gift Card',
  gift_card_20_euro_desc:
    '20€ to spend as you wish! Store, website… treat yourself!',
  points: 'points',
  get_reward: 'Get the reward',
  exclusive_rewards_wait: 'EXCLUSIVE REWARDS AWAIT YOU',
  all: 'All',
  gift_cards: 'Gift Cards',
  partner_offers: 'Partner Offers',
  register: 'Sign up',
  learn_more: 'Learn more',
  my_membership: 'My Membership',

  join_loyalty_program: 'Join our loyalty program:',
  first_250_points_offered: 'your first 250 points are offered',
  welcome_gift_250_points:
    'Receive a welcome gift of 250 points, equivalent to €10 off your next purchase in store or on jeanmarcphilippe.com',
  want_to_know_more: 'Want to know more?',
  'You may also like': 'You may also like',
  'Open the conversation': 'Ouvrir la conversation',
  'Contact-us': 'Contact-us',
  'Evaluate the assistance': 'Evaluate the assistance',

  'JMP loyalty club': 'JMP loyalty club',
  'Only points left until the next reward!':
    'Only <b>{points} points</b> left until the next reward!',
  Orders: 'Orders',
  Settings: 'Settings',
  'A question ?': 'A question?',
  'customer service': 'Customer service',
  'My status': 'My status',
  'you only have {points} points left to reach {status}':
    'You only have <b>{points}</b> points left to reach <b>{status}</b>',

  'With each of your purchases or events, earn points and benefit from rewards!':
    'With each of your purchases or events, earn points and benefit from rewards !',

  'Use your points and choose the reward that makes you the happiest! Your rewards can be used on our site and also in stores':
    'Use your points and choose the reward that makes you the happiest! Your rewards can be used on our site and also in stores',
  showAlertReturnCostText:
    'In order to make an exchange or refund, you will need to prepare the package and print a return label to identify it. Return for exchange is a free service. In the event of a refund for one of your items, this service costs <b>{price}</b> and the refund period is 10 and 15 working days from receipt of the return package. See the general conditions of sale.',
  //Routing -----------------------
  account_profil: '/account/informations',
  referal_path_faq: '/content/faq/sponsor-a-friend',

  newsletterCheckbox: {
    title: 'JMP News',
    email_text:
      'Yes, I want to receive information about the latest news and enjoy exclusive offers and discounts by email. Unsubscribe at any time.',
    sms_text:
      'Yes, I want to receive information about the latest news and enjoy exclusive offers and discounts by SMS. Unsubscribe at any time.',
  },
  conatctus: {
    confirmedText: ` <div>
                        Thank you for contacting us! <br />
                        We have received your message, and our team will do its best to respond
                        to you as soon as possible.
                      </div>
                      <br />
                      <div>Thank you for your trust, and see you soon!</div>`,
    button: { send_another_message: 'Send another message' },
  },
  'Open main menu': 'Open main menu',

  browsingOut: {
    welcome: 'Welcome',
    message: 'It seems that you are browsing from <b>{country}</b>',
    changeCountry: 'Change country',
    prices: 'Prices in GBP and incl. VAT',
    noCustoms: 'No customs duties to pay',
    freeShipping: 'Free shipping with DHL',
    arrivalTime: 'Arrives in 2 business days',
    freeReturns: 'Free returns within 14 days',
    continue: 'Continue',
  },
};

// export {
//   EN
// };
export default async (context, locale) => {
  return await Promise.resolve(EN);
};
