import Vue from 'vue';
import { Navigation, Autoplay, Pagination, EffectFade } from 'swiper';

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);
